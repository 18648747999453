// stores/auth.js
import { defineStore } from 'pinia';
import apiClient from '@/api/axios';
import { jwtDecode } from 'jwt-decode';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null,
    user: null,
  }),
  actions: {
    async login(credentials) {
      try {
        const response = await apiClient.post('/auth/login', credentials);
        if (response.data.code == 200) {
          this.setToken(response.data.data.token, credentials.username);
          //console.log(response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message);
      }
    },
    async hospitallist() {
      try {
        const response = await apiClient.get('/hospital/list');
        if (response.data.code === 200) {
          return response.data;
        } else {
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message);
      }
    },
    async devicelist() {
      try {
        const response = await apiClient.get('/device/sync/list');
        if (response.data.code === 200) {
          return response.data;
        } else {
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message);
      }
    },
    async getDepartmentList(code) {
      try {
        const response = await apiClient.get(`/hospital/${code}/department`);
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    //http://192.168.110.250:8281/register/patient/list?t=1725514101141&pageNumber=1&pageSize=50
    //查询患者列表
    async getPatients(params) {
      try {
        const response = await apiClient.get(`/register/patient/list`, { params });
        if (response.data.code === 200) {
          //console.log("/register/patient/list>" + response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    //http://192.168.110.250:8281/register/order/list?t=1725551048956&visitType=0201&visitNo=1000025142
    async getOrders(params) {
      try {
        const response = await apiClient.get(`/register/order/list`, { params });
        if (response.data.code === 200) {
          //console.log("/register/order/list>" + response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    //生成条码
    async accessionGenerate(credentials) {
      try {
        const response = await apiClient.put('/register/order/accession/generate', credentials);
        if (response.data.code == 200) {
          return response.data;
        } else {

          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message);
      }
    },
    async accessionRemove(credentials) {
      try {
        const response = await apiClient.put('/register/order/accession/remove', credentials);
        if (response.data.code == 200) {
          return response.data;
        } else {

          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message);
      }
    },

    //报告查询http://192.168.110.250:8281/sample/search?t=1725845928051&deviceId=6F1D3D80-02BD-EB11-80B6-005056925F97
    //&startCheckTime=1693497600&endCheckTime=1696089599&pageIndex=2&pageSize=50
    async getSamples(params) {
      try {
        const response = await apiClient.get(`/sample/search`, { params });
        if (response.data.code === 200) {
          //console.log("/sample/search>" + response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    //http://192.168.110.250:8281/patient/list?accessionNum=2023326539 根据条码获取病人信息
    async getPatient(params) {
      try {
        const response = await apiClient.get(`/patient/list`, { params });
        if (response.data.code === 200) {
          //console.log("/patient/list>" + response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    //http://192.168.110.250:8281/patient/2023397250/params?nearTimes=10 获取最近3次检查数据
    async getRecentInfo(patientId, nearTimes) {
      try {
        const response = await apiClient.get(`/patient/${patientId}/params?nearTimes=${nearTimes}`);
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    //sample/5121E73D-475E-EE11-8AC2-005056B71022/params
    async getSample(guID) {
      try {
        const response = await apiClient.get(`/sample/${guID}/params`);
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    //通过id查询名字,http://192.168.110.250:8281/user/list?emplIds=6d459fe9-5afd-480f-b659-df8a174e7975&pageIndex=1&pageSize=1
    async getUser(params) {
      try {
        const response = await apiClient.get(`/user/list`, { params });
        if (response.data.code === 200) {
          //console.log("/user/list>" + response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },

    //生成条码
    async sampleSave(credentials) {
      try {
        const response = await apiClient.post('/sample/save', credentials);
        if (response.data.code == 200) {
          return response.data;
        } else {

          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message);
      }
    },
    ///sample/update/status
    async sampleUpdate(credentials) {
      try {
        const response = await apiClient.put('/sample/update/status', credentials);
        if (response.data.code == 200) {
          return response.data;
        } else {

          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message);
      }
    },
    ///sample/update/status
    async sampleRecycle(credentials) {
      try {
        const response = await apiClient.post('/sample/Recycle', credentials);
        if (response.data.code == 200) {
          return response.data;
        } else {

          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message);
      }
    },
    //通过用户名关键字查询用户
    async patientSearch(keyword) {
      try {
        const response = await apiClient.get('/patient/search_key', {
          params: {
            searchKey: keyword // 将 searchKey 转换为查询参数
          }
        });
        if (response.data.code == 200) {
          return response.data;
        } else {
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message);
      }
    },

    ///sample/28538D45-845D-EE11-8AC2-005056B71022/print打印报告
    async samplePrint(guID) {
      try {
        const response = await apiClient.get(`/sample/${guID}/print`, {
          responseType: 'blob'
        });
        return response;
      } catch (error) {
        throw new Error('获取PDF文件失败: ' + error.message);
      }
    },
    async getPDF(params) {
      try {
        const response = await axios.post('/sample//print', params, {
          responseType: 'blob' // 这是关键，它告诉 Axios 服务器返回二进制数据
        });
        return response;
      } catch (error) {
        throw new Error('获取PDF文件失败: ' + error.message);
      }
    },
    async getRoleList() {
      try {
        const response = await apiClient.get(`/role/list`);
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data;
        } else {
          //console.log(response.data.msg);
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    setToken(token, user) {
      this.token = token;
      localStorage.setItem('token', token); // 将 token 存储在 localStorage 中
      localStorage.setItem('user', user);
    },
    clearToken() {
      this.token = null;
      this.user = null;
      localStorage.removeItem('token'); // 清除 localStorage 中的 token
      localStorage.removeItem('user');
    },
    loadToken() {
      this.token = localStorage.getItem('token');
      this.user = localStorage.getItem('user');
    },
    isTokenExpired() {
      if (!this.token) return true;
      try {
        const decoded = jwtDecode(this.token);
        const currentTime = Date.now() / 1000;
        return decoded.exp < currentTime;
      } catch (error) {
        return true;
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token && !state.isTokenExpired(),
    getToken: (state) => state.token,
  },
});