import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createPinia } from 'pinia';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/theme-chalk/index.css';
import '@/assets/css/font.css'
import { useAuthStore } from '@/stores/authStore';
const pinia = createPinia();
const app=createApp(App)
  .use(pinia)
  .use(store)
  .use(router)
  .use(ElementPlus, { locale: zhCn });
app.mount('#app');
const authStore = useAuthStore();
authStore.loadToken();
