<template>
  <div class="department-management-container">
    <div class="department-management-box">
      <div class="header">
        <span class="title">科室管理</span>
        <el-input v-model="search" placeholder="请输入内容" class="search-input" clearable>
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="syncDepartment" class="sync-button">同步科室</el-button>
        <el-button type="primary" @click="openCreateModal" class="create-button">创建科室</el-button>
      </div>
      <div class="table-container">
        <el-table :data="paginatedDepartments" style="width: 98%;" :header-cell-style="{background:'#F5F6F7'}" class="custom-table">
          <el-table-column prop="id" label="科室ID" align="left" header-align="left"></el-table-column>
          <el-table-column prop="name" label="科室名称" align="left" header-align="left"></el-table-column>
          <el-table-column prop="code" label="科室代码" align="left" header-align="left"></el-table-column>
          <el-table-column prop="area" label="所属院区" align="left" header-align="left"></el-table-column>
          <el-table-column prop="updated" label="更新时间" align="left" header-align="left"></el-table-column>
          <el-table-column label="操作" align="center" header-align="center">
            <template v-slot="scope">
              <el-button @click="openEditModal(scope.row)" type="button" class="action1">
                <el-icon size="16">
                  <Edit />
                </el-icon> <span>编辑</span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next" :total="filteredDepartments.length" :page-size="pageSize"
          @current-change="handlePageChange" class="pagination" />
        <div class="pagination-info">
          共 {{ filteredDepartments.length }} 条
        </div>
      </div>
    </div>

    <!-- Modal -->
    <el-dialog v-model="isModalVisible" width="360px" height="244px" :before-close="closeModal" style="border-radius: 8px;" class="custom-dialog">
      <template #title>
        <span>{{ isEdit ? '编辑科室' : '创建科室' }}</span>
      </template>
      <el-form :model="form">
        <el-form-item label="科室名称">
          <el-input v-model="form.name" placeholder="请输入科室名称"></el-input>
        </el-form-item>
        <el-form-item label="科室代码">
          <el-input v-model="form.code" placeholder="请输入科室代码"></el-input>
        </el-form-item>
        <el-form-item label="所属院区">
          <el-input v-model="form.area" placeholder="请输入所属院区"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModal">关闭</el-button>
        <el-button type="primary" class="ctrl" @click="handleSave">{{ isEdit ? '保存' : '创建' }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Search, Edit } from '@element-plus/icons-vue';

export default {
  components: {
    Search,
    Edit
  },
  data() {
    return {
      search: '',
      departments: [
        // 模拟数据
        { id: '001', name: '科室1', code: '001', area: '院区1', updated: '2023-07-01' },
        { id: '002', name: '科室2', code: '002', area: '院区2', updated: '2023-07-02' },
        // 添加更多数据
        { id: '003', name: '科室3', code: '003', area: '院区3', updated: '2023-07-03' },
        { id: '004', name: '科室4', code: '004', area: '院区4', updated: '2023-07-04' },
        { id: '005', name: '科室5', code: '005', area: '院区5', updated: '2023-07-05' },
        { id: '006', name: '科室6', code: '006', area: '院区6', updated: '2023-07-06' },
        { id: '007', name: '科室7', code: '007', area: '院区7', updated: '2023-07-07' },
        { id: '008', name: '科室8', code: '008', area: '院区8', updated: '2023-07-08' },
        { id: '009', name: '科室9', code: '009', area: '院区9', updated: '2023-07-09' },
        { id: '010', name: '科室10', code: '010', area: '院区10', updated: '2023-07-10' },
        { id: '011', name: '科室11', code: '011', area: '院区11', updated: '2023-07-11' },
        { id: '012', name: '科室12', code: '012', area: '院区12', updated: '2023-07-12' },
        { id: '013', name: '科室13', code: '013', area: '院区13', updated: '2023-07-13' },
        { id: '014', name: '科室14', code: '014', area: '院区14', updated: '2023-07-14' },
        { id: '015', name: '科室15', code: '015', area: '院区15', updated: '2023-07-15' },
        { id: '016', name: '科室16', code: '016', area: '院区16', updated: '2023-07-16' },
        { id: '017', name: '科室17', code: '017', area: '院区17', updated: '2023-07-17' },
        { id: '018', name: '科室18', code: '018', area: '院区18', updated: '2023-07-18' },
        { id: '019', name: '科室19', code: '019', area: '院区19', updated: '2023-07-19' },
        { id: '020', name: '科室20', code: '020', area: '院区20', updated: '2023-07-20' },
        { id: '021', name: '科室21', code: '021', area: '院区21', updated: '2023-07-21' },
        { id: '022', name: '科室22', code: '022', area: '院区22', updated: '2023-07-22' },
        { id: '023', name: '科室23', code: '023', area: '院区23', updated: '2023-07-23' },
        { id: '024', name: '科室24', code: '024', area: '院区24', updated: '2023-07-24' },
        { id: '025', name: '科室25', code: '025', area: '院区25', updated: '2023-07-25' }
      ],
      currentPage: 1,
      pageSize: 10,
      isModalVisible: false,
      isEdit: false,
      form: {
        id: '',
        name: '',
        code: '',
        area: ''
      },
      currentDepartment: null
    };
  },
  computed: {
    filteredDepartments() {
      if (!this.search) return this.departments;
      return this.departments.filter(department =>
        Object.values(department).some(value => value.includes(this.search))
      );
    },
    paginatedDepartments() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredDepartments.slice(start, end);
    }
  },
  methods: {
    syncDepartment() {
      console.log('同步科室');
      // 同步科室的逻辑
    },
    openCreateModal() {
      this.isEdit = false;
      this.form = { id: '', name: '', code: '', area: '' };
      this.isModalVisible = true;
    },
    openEditModal(department) {
      this.isEdit = true;
      this.form = { ...department };
      this.currentDepartment = department;
      this.isModalVisible = true;
    },
    handleSave() {
      if (this.isEdit) {
        Object.assign(this.currentDepartment, this.form);
        console.log('编辑科室', this.currentDepartment);
      } else {
        this.departments.push({ ...this.form, updated: new Date().toISOString().split('T')[0] });
        console.log('创建科室', this.form);
      }
      this.isModalVisible = false;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    handlePageChange(page) {
      this.currentPage = page;
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.department-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.department-management-box {
  width: calc(100% - 20px);
  height: calc(100vh - 40px);
  background-color: #F0F3F5;
  border-radius: 12px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: #057AB2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.search-input {
  width: 300px;
  margin-right: 20px;
}

.sync-button,
.create-button {
  margin-left: 20px;
  background-color: #057AB2;
  border-color: #057AB2;
}

.sync-button:hover,
.create-button:hover {
  background-color: #005f8a;
  border-color: #005f8a;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
}

.custom-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  overflow: auto;
}

.el-table th,
.el-table td {
  text-align: center;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.pagination-container {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.dialog-footer {
  text-align: right;
}

.ctrl {
  width: 88px;
  height: 32px;
  background-color: #057AB2;
}

.ctrl:hover {
  background-color: #005f8a;
  border-color: #005f8a;
}

.action1 {
  border: none;
  color: #057ab2;
  background-color: transparent;
}

.action1:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057ab2;
  border: none;
}
</style>
