<template>
  <div class="registration-page">
    <div class="header">
      <div class="filter-bar">
        <!-- 过滤项 -->
        <div class="filter-item">
          <label for="dateStart">检验日期</label>
          <el-date-picker v-model="dateStart" type="date" placeholder="申请日期" class="filter-date" />-
          <el-date-picker v-model="dateEnd" type="date" placeholder="截止日期" class="filter-date" />
        </div>
        <div class="filter-item">
          <label for="hospital">医院</label>
          <el-select v-model="hospital" placeholder="选择医院" class="filter-input" @change="fetchDepartments">
            <el-option v-for="hospital in hospitalList" :key="hospital.hospitalCode" :label="hospital.hospitalName"
              :value="hospital.hospitalCode">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="department">科室</label>
          <el-select v-model="department" placeholder="选择科室" class="filter-input" @change="filterDevicesByDepartment">
            <el-option v-for="department in departmentList" :key="department.code" :label="department.name"
              :value="department.id">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="device">设备</label>
          <el-select v-model="device" placeholder="选择设备" class="filter-input">
            <el-option v-for="device in filteredDevices" :key="device.id" :label="device.name" :value="device.id">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="patient">患者</label>
          <el-select v-model="patient" placeholder="姓名关键字" class="filter-input">
            <el-option label="患者A" value="A"></el-option>
            <el-option label="患者B" value="B"></el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="status">报告状态</label>
          <el-select v-model="status" placeholder="选择状态" class="filter-input">
            <el-option label="已完成" value="A"></el-option>
            <el-option label="未完成" value="B"></el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <el-checkbox label="已打印"></el-checkbox>
        </div>
      </div>
      <div class="button-group">
        <el-button type="primary" @click="search" class="ctrl-button">查询</el-button>
        <el-button @click="reset" class="ctrl-button">重置</el-button>
      </div>
    </div>
    <div class="content">
      <div class="report-title">报告信息</div>
      <!-- 使用 splitpanes 组件实现左右拖动 -->
      <splitpanes class="default-theme" @resize="onResize" :push-other-panes="false">
        <pane min-size="71">
          <div class="left-pane">
            <el-table :data="patients" height="100%" width="100%" @row-click="handleRowClick">
              <!-- 左侧表格列 -->
              <el-table-column type="selection" width="30" align="center"></el-table-column>
              <el-table-column prop="inspectionTime" label="检验时间" width="auto" min-width="100"></el-table-column>
              <el-table-column prop="sampleNumber" label="样本号" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="status" label="状态" width="auto" min-width="60"></el-table-column>
              <el-table-column prop="patientID" label="患者ID" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="patientName" label="患者姓名" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="patientSource" label="患者来源" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="hospitalNumber" label="住院号" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="barcodeNumber" label="条码号" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="cardNumber" label="卡号" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="bedNumber" label="床号" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="gender" label="性别" width="auto" min-width="60"></el-table-column>
              <el-table-column prop="birthDate" label="出生日期" width="auto" min-width="80"></el-table-column>
            </el-table>
            <div class="pagination-container">
              <div class="pagination">
                <el-pagination layout="prev, pager, next" :total="totalRecords" background :page-size="20"></el-pagination>
              </div>
              <div class="total-records">共 {{ totalRecords }} 条</div>
            </div>
          </div>
        </pane>
        <pane>
          <div class="right-pane">
            <div class="table-container">
              <el-table :data="selectedRecords" ref="rightTable" height="calc(100% - 100px)" width="100%">
                <!-- 右侧表格列 -->
                <el-table-column prop="serialNumber" label="序号" width="auto" min-width="50"></el-table-column>
                <el-table-column prop="parameter" label="参数" width="auto" min-width="80"></el-table-column>
                <el-table-column prop="projectName" label="项目名称" width="auto" min-width="120"></el-table-column>
                <el-table-column prop="result" label="结果" width="auto" min-width="100"></el-table-column>
                <el-table-column prop="hint" label="提示" width="auto" min-width="100"></el-table-column>
                <el-table-column prop="unit" label="单位" width="auto" min-width="80"></el-table-column>
                <el-table-column prop="referenceRange" label="参考范围" width="auto" min-width="120"></el-table-column>
              </el-table>
            </div>
            <div v-if="hasReportInfo" class="report-action">
              <div class="report-info">
                <div v-if="selectedRecords[0]?.status" class="info-item">
                  <span class="info-label"><img src="@/assets/feiji.png" width="13px" /></span><span class="info-label">报告状态：</span><span class="status-published">{{ selectedRecords[0]?.status }}</span>
                </div>
                <div v-if="selectedRecords[0]?.printStatus" class="info-item">
                  <span class="info-label"><img src="@/assets/dayin.png" width="13px" /></span><span class="info-label">打印状态：</span><span class="status-not-printed">{{ selectedRecords[0]?.printStatus }}</span>
                </div>
                <div v-if="selectedRecords[0]?.reviewer" class="info-item">
                  <span class="info-label"><img src="@/assets/yinzhang.png" width="13px" /></span><span class="info-label">审核人：</span>{{ selectedRecords[0]?.reviewer }}
                </div>
                <div v-if="selectedRecords[0]?.reviewTime" class="info-item">
                  <span class="info-label"><img src="@/assets/shijian.png" width="13px" /></span><span class="info-label">审核时间：</span>{{ selectedRecords[0]?.reviewTime }}
                </div>
              </div>
              <div class="action-buttons">
                <el-button type="danger" @click="revokeBarcode" class="barcode-button">
                  回收
                </el-button>
                <el-button type="primary" @click="generateBarcode" class="barcode-button">
                  打印
                </el-button>
              </div>
            </div>
          </div>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>


<script>
import { useAuthStore } from '@/stores/authStore';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

export default {
  components: {
    Splitpanes,
    Pane,
  },
  data() {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    return {
      patient: '',
      device: '',
      hospital: '',
      department: '',
      dateStart: thirtyDaysAgo,
      dateEnd: today,
      status: '',
      currentPage: 1,
      totalRecords: 507,
      selectedRecords: [],
      selectedRows: [],
      patients: [
        { bedNumber: 43, patientNumber: '7797039363', name: '李波', gender: '男', age: 56 },
        { bedNumber: 37, patientNumber: '1641416408', name: '柳三木', gender: '男', age: 27 },
      ],
      allRecords: [
        {
          serialNumber: 1,
          parameter: 'pH',
          projectName: '酸碱度',
          result: '7.408',
          hint: '正常',
          unit: '',
          referenceRange: '7.35 - 7.45',
          status: '已发布',
          printStatus: '未打印',
          reviewer: '刘德军',
          reviewTime: '2024-04-03 05:45:29',
        },
        {
          serialNumber: 2,
          parameter: 'pCO2',
          projectName: '二氧化碳分压',
          result: '40',
          hint: '',
          unit: 'mmHg',
          referenceRange: '35 - 45',
          status: '已发布',
          printStatus: '未打印',
          reviewer: '刘德军',
          reviewTime: '2024-04-03 05:45:29',
        },
      ],
      hospitalList: [],
      departmentList: [],
      deviceList: [], // 全部设备列表
      filteredDevices: [], // 过滤后的设备列表
    };
  },
  computed: {
    hasReportInfo() {
      return (
        this.selectedRecords.length > 0 &&
        (this.selectedRecords[0].status ||
          this.selectedRecords[0].printStatus ||
          this.selectedRecords[0].reviewer ||
          this.selectedRecords[0].reviewTime)
      );
    },
  },
  methods: {
    onResize() {
      this.$refs.rightTable.doLayout(); // 手动触发表格布局调整
    },
    async fetchHospitals() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.hospitallist();
        if (response.code === 200) {
          this.hospitalList = response.data;
          if (this.hospitalList.length > 0) {
            this.hospital = this.hospitalList[0].hospitalCode;
            await this.fetchDepartments(this.hospital);
          }
        }
      } catch (error) {
        console.error('Error fetching hospitals:', error);
      }
    },
    async fetchDepartments(hospitalCode) {
      try {
        const authStore = useAuthStore();
        const response = await authStore.getDepartmentList(hospitalCode);
        if (response.code === 200) {
          this.departmentList = response.data;
          if (this.departmentList.length > 0) {
            this.department = this.departmentList[0].id; // 自动选择第一个科室
            await this.filterDevicesByDepartment(); // 自动获取该科室下的设备
          }
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    },
    async fetchDevices() { // 获取设备列表
      try {
        const authStore = useAuthStore();
        const response = await authStore.devicelist();
        if (response.code === 200) {
          this.deviceList = response.data;
          await this.filterDevicesByDepartment(); // 初次加载时过滤设备
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    },
    filterDevicesByDepartment() { // 根据选中的科室过滤设备
      if (this.department) {
        this.filteredDevices = this.deviceList.filter(device => device.deptId === this.department);
        if (this.filteredDevices.length > 0) {
          this.device = this.filteredDevices[0].id; // 默认选择第一个设备
        }
      } else {
        this.filteredDevices = [];
        this.device = ''; // 如果没有科室选中，则清空设备选择
      }
    },
    search() {
      console.log('搜索条件:', this.hospital, this.department, this.dateStart, this.dateEnd, this.status);
      // 实现搜索逻辑
    },
    reset() {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);
      this.hospital = '';
      this.department = '';
      this.dateStart = thirtyDaysAgo;
      this.dateEnd = today;
      this.status = '';
      console.log('重置条件');
      // 实现重置逻辑
    },
    handleRowClick(row) {
      console.log('选中的行:', row);
      // 模拟根据患者信息加载右侧的记录
      this.selectedRecords = this.allRecords.map(record => {
        return { ...record };
      });
    },
    generateBarcode() {
      console.log('Generating barcode...');
      // Logic to generate barcode
    },
    revokeBarcode() {
      console.log('Revoking barcode...');
      // Logic to revoke barcode
    },
  },
  async mounted() {
    await this.fetchHospitals();
    await this.fetchDevices(); // 获取设备列表
  },
};
</script>
<style scoped>
.registration-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
  width: calc(100% - 20px);
  background-color: #474747;
  overflow: hidden;
  margin-bottom: 20px;
  font-weight: 400;
}

.header {
  background-color: #474747;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.filter-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.filter-item {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}

:deep(.filter-item .el-checkbox__label) {
  color: white;
  font-size: 16px;
}

.filter-input,
.filter-date {
  flex: 1;
  min-width: 150px;
}

.button-group {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.ctrl-button {
  width: 88px;
}

.registration-page .content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  border-radius: 12px;
  background-color: #f0f3f5;
  overflow: hidden;
}

.splitpanes__pane {

  display: flex;
  justify-content: center;
  align-items: center;
}

.report-title {
  padding: 10px;
  color: #057AB2;
  font-weight: 500;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.splitpanes {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.splitpanes__pane {
  overflow: hidden; /* 禁止任何滚动条出现 */
}

.left-pane {
  padding: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  border-radius: 12px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  gap: 10px;
}

:deep(.left-pane .el-table .el-table__cell) {
  padding: 3px 0;
}

:deep(.right-pane .el-table .el-table__cell) {
  padding: 3px 0;
}

.right-pane {
  flex: 1;
  background-color: white;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  height: 100%;
}

.table-container {
  padding: 5px;
  height: calc(100% - 70px);
  box-sizing: border-box;
}

.report-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 0;
}

.report-info {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 10px;
  font-size: 14px;
  color: #666;
}

.info-item {
  display: flex;
  align-items: center;
}

.info-label {
  margin-right: 5px;
}

.status-published {
  color: green;
}

.status-not-printed {
  color: orange;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.barcode-button {
  width: 88px;
  margin: 0;
}
</style>


