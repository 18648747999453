<template>
  <div class="registration-page">
    <div class="header">
      <div class="filter-bar">
        <label for="hospital">医院</label>
        <el-select v-model="hospital" placeholder="选择医院" class="filter-input small-font">
          <el-option v-for="hospital in hospitalList" :key="hospital.hospitalCode" :label="hospital.hospitalName"
            :value="hospital.hospitalCode">
          </el-option>
        </el-select>

        <label for="department">科室</label>
        <el-select v-model="department" placeholder="选择科室" class="filter-input small-font"
          @change="filterDevicesByDepartment">
          <el-option v-for="department in departmentList" :key="department.id" :label="department.name"
            :value="department.id">
          </el-option>
        </el-select>

        <label for="device">设备</label>
        <el-select v-model="device" placeholder="选择设备" class="filter-input small-font">
          <el-option v-for="device in filteredDevices" :key="device.id" :label="device.name" :value="device.id">
          </el-option>
        </el-select>

        <label for="dateStart">检验日期</label>
        <el-date-picker v-model="dateStart" type="date" placeholder="申请日期" class="filter-date small-font" />
        -
        <el-date-picker v-model="dateEnd" type="date" placeholder="截止日期" class="filter-date small-font" />

        <label for="status">筛选状态</label>
        <el-select v-model="status" placeholder="选择状态" class="filter-input small-font">
          <el-option label="待绑定" value="await"></el-option>
          <el-option label="已保存" value="saved"></el-option>
          <el-option label="已审核" value="audited"></el-option>
          <el-option label="已发布" value="publish"></el-option>
          <el-option label="已作废" value="invalid"></el-option>
        </el-select>
      </div>
      <div class="button-group">
        <el-button type="primary" @click="search" class="ctrl-button small-font">查询</el-button>
        <el-button @click="reset" class="ctrl-button small-font">重置</el-button>
      </div>
    </div>
    <el-container class="content">
      <el-header class="top">检测检查</el-header>
      <el-container>
        <el-aside class="left-pane small-font" width="340px">
          <el-table :data="samples" height="100%" width="100%" @row-click="handleRowClick" highlight-current-row
            ref="leftTable" class="small-table" :default-sort="{ prop: 'inspectionTime', order: 'descending' }">
            <el-table-column prop="analyzeDate" @current-change="handleCurrentChange"
              :row-class-name="tableRowClassName" label="检验时间" width="auto" min-width="43%" sortable><template
                v-slot:default="scope">
                {{ formatDateTime(scope.row.analyzeDate) }}
              </template></el-table-column>
            <el-table-column prop="sampleNum" label="样本号" width="auto" min-width="32%" sortable></el-table-column>
            <el-table-column prop="applyStatus" label="状态" width="auto" min-width="25%" sortable><template
                v-slot:default="scope">
                {{ mapStatus(scope.row.applyStatus) }}
              </template></el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination layout="prev, pager, next, total" size="small" :total="totalRecords" background
              :page-size="pageSize" :pager-count="5" :current-page="currentPage" @current-change="handlePageChange"
              @size-change="handleSizeChange">
            </el-pagination>
          </div>
        </el-aside>
        <el-container class="right-pane">
          <el-header class="form-header" style="padding: 0px;" height="auto">
            <template
              v-if="selectedSample.applyStatus !== '' && selectedSample.applyStatus != 'await' && selectedSample.applyStatus != null">
              <div class="grid-container">
                <!-- 第一列 -->
                <div class="grid-col">
                  <div class="grid-item">
                    <span style="font-size: 16px">{{ patientDetails.name }}</span> &nbsp;&nbsp;<span
                      style="color:#45494C">{{ patientDetails.bedNum }}</span>
                  </div>
                  <div class="grid-item">
                    <span style="color:#45494C">{{ patientDetails.sex === 'woman' ? '女' : '男' }}</span> /
                    <span style="color:#45494C">{{ patientDetails.birthdayDate }}</span> /
                    <span>{{ patientDetails.age }}岁</span>
                  </div>
                  <div class="grid-item">
                    <label class="label2">患者ID：</label><span>{{ patientDetails.patientId }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第二列 -->
                <div class="grid-col">
                  <div class="grid-item">
                    <label class="label2">住院号</label><span>{{ patientDetails.inpatNum }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label2">条码号</label><span>{{ patientDetails.accessionNum }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label2">卡号</label><span>{{ patientDetails.cardNum }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第三列 -->
                <div class="grid-col">
                  <div class="grid-item">
                    <label class="label3">诊断：</label><span>{{ patientDetails.diagnosis }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">来源：</label><span>{{ patientDetails.sourceCode }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">描述：</label><span>{{ patientDetails.remark }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第四列 -->
                <div class="grid-col">
                  <div class="grid-item double-height">
                    <label class="label3">地址：</label><span style="text-wrap:wrap;">{{ patientDetails.address }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">电话：</label><span>{{ patientDetails.phone }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第五列 -->
                <div class="grid-col">
                  <div class="grid-item">
                    <label class="label3">样本类型：</label><span>{{ patientDetails.sampleType }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">样本说明：</label><span>{{ patientDetails.sampleDesc }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第六列 -->
                <div class="grid-col">
                  <div class="grid-item">
                    <label class="label4">采</label><span>{{ patientDetails.sampleCollectionDate }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label4">收</label><span>{{ patientDetails.sampleReceiveDate }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label4">测</label><span>{{ patientDetails.analyzeDate }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第七列 -->
                <div class="grid-col">
                  <div class="grid-item">
                    <label class="label3">申请医生：</label><span>{{ patientDetails.applyDoctorName }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">检测医生：</label><span>{{ patientDetails.analyzeDoctorName }}</span>
                  </div>
                </div>
              </div>
            </template>
            <!-- 当状态为  待绑定 -->
            <template v-else>
              <el-row :gutter="10" class="form-row">
                <el-col :span="6">
                  <label>*患者姓名：</label>
                  <el-input v-model="patientDetails.name" placeholder="请输入" size="small" class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>性别：</label>
                  <el-select v-model="patientDetails.sex" placeholder="请选择" size="small" class="small-font">
                    <el-option label="男" value="man"></el-option>
                    <el-option label="女" value="woman"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <label>床号：</label>
                  <el-input v-model="patientDetails.bedNum" placeholder="请输入" size="small"
                    class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>出生日期：</label>
                  <el-date-picker v-model="patientDetails.birthdayDate" placeholder="请选择" style="width:160px"
                    size="small" class="small-font"></el-date-picker>
                </el-col>
                <el-col :span="6">
                  <label>*患者ID：</label>
                  <el-input v-model="patientDetails.patientId" placeholder="请输入" size="small"
                    class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>住院号：</label>
                  <el-input v-model="patientDetails.inpatNum" placeholder="请输入" size="small"
                    class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>条码号：</label>
                  <el-input v-model="selectedSample.accessionNum" placeholder="请输入" @keyup.enter="fetchPatient"
                    size="small" class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>卡号：</label>
                  <el-input v-model="patientDetails.cardNum" placeholder="请输入" size="small"
                    class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>诊断：</label>
                  <el-input v-model="patientDetails.diagnosis" placeholder="请输入" size="small"
                    class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>来源：</label>
                  <el-select v-model="patientDetails.sourceCode" placeholder="请选择患者来源" size="small" class="small-font">
                    <el-option label="门诊" value="outpatient"></el-option>
                    <el-option label="住院" value="inpatient"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <label>描述：</label>
                  <el-input v-model="patientDetails.remark" placeholder="请输入患者描述" size="small"
                    class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>地址：</label>
                  <el-input v-model="patientDetails.address" placeholder="请输入" size="small"
                    class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>电话：</label>
                  <el-input v-model="patientDetails.phone" placeholder="请输入" size="small" class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>样本类型：</label>
                  <el-select v-model="patientDetails.sampleType" placeholder="选择样本类型" size="small" class="small-font">
                    <el-option label="全血" value="全血"></el-option>
                    <el-option label="静脉血" value="静脉血"></el-option>
                    <el-option label="ICU动脉血" value="ICU动脉血"></el-option>
                    <el-option label="全血" value="全血"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <label>样本说明：</label>
                  <el-input v-model="patientDetails.sampleDesc" placeholder="请输入" size="small"
                    class="small-font"></el-input>
                </el-col>
                <el-col :span="6">
                  <label>*采样时间：</label>
                  <el-date-picker type="datetime" v-model="patientDetails.sampleCollectionDate" placeholder="请选择"
                    size="small" style="width:160px" class="small-font"></el-date-picker>
                </el-col>
                <el-col :span="6">
                  <label>*接收时间：</label>
                  <el-date-picker v-model="patientDetails.sampleReceiveDate" type="datetime" placeholder="请选择"
                    size="small" style="width:160px" class="small-font"></el-date-picker>
                </el-col>
                <el-col :span="6">
                  <label>*检测时间：</label>
                  <el-date-picker v-model="selectedSample.analyzeDate" type="datetime" placeholder="请选择" size="small"
                    style="width:160px" class="small-font"></el-date-picker>
                </el-col>
                <el-col :span="6">
                  <label>申请医生：</label>
                  <el-select v-model="patientDetails.applyDoctorName" placeholder="请选择" size="small" class="small-font"
                    filterable remote reserve-keyword :remote-method="fetchDoctors" @change="handleApplyDoctorChange">
                    <!-- 如果当前有检测医生数据，先显示当前的医生 -->
                    <el-option v-if="patientDetails.applyDoctorName" :key="patientDetails.applyDoctorCode"
                      :label="`${patientDetails.applyDoctorName} (${patientDetails.applyDoctorCode})`"
                      :value="patientDetails.applyDoctorName">
                    </el-option>
                    <!-- 其他医生选项 -->
                    <el-option v-for="doctor in doctorList" :key="doctor.code"
                      :label="`${doctor.name} (${doctor.code})`" :value="doctor.name">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <label>*检测医生：</label>
                  <el-select v-model="patientDetails.analyzeDoctorName" placeholder="请选择" size="small"
                    class="small-font" filterable remote reserve-keyword :remote-method="fetchDoctors"
                    @change="handleAnalyzeDoctorChange">
                    <!-- 如果当前有检测医生数据，先显示当前的医生 -->
                    <el-option v-if="patientDetails.analyzeDoctorName" :key="patientDetails.analyzeDoctorCode"
                      :label="`${patientDetails.analyzeDoctorName} (${patientDetails.analyzeDoctorCode})`"
                      :value="patientDetails.analyzeDoctorName">
                    </el-option>
                    <!-- 其他医生选项 -->
                    <el-option v-for="doctor in doctorList" :key="doctor.code"
                      :label="`${doctor.name} (${doctor.code})`" :value="doctor.name">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </template>
          </el-header>

          <!-- 下方内容区域 -->
          <el-main class="content-area">
            <div style="position: relative;">
              <el-tabs>
                <el-tab-pane label="检查项目">
                  <!-- 使用el-row和el-col布局两个el-table -->
                  <el-row :gutter="10" style="height: 100%;">
                    <el-col :span="12">
                      <el-scrollbar style="height: 100%;">
                        <el-table :data="inspectionItems.slice(0, Math.ceil(inspectionItems.length / 2))" size="small"
                          style="width: 100%;">
                          <el-table-column prop="index" label="序号" width="auto" min-width="10%"></el-table-column>
                          <el-table-column prop="parameter" label="参数" width="auto" min-width="20%"></el-table-column>
                          <el-table-column prop="itemName" show-overflow-tooltip label="项目名称" width="auto"
                            min-width="30%"></el-table-column>
                          <el-table-column prop="result" label="结果" width="auto" min-width="15%"></el-table-column>
                          <el-table-column prop="hint" label="提示" width="auto" min-width="10%">
                            <template #default="scope">
                              <span :class="{ 'high': scope.row.hint === '↑', 'low': scope.row.hint === '↓' }">
                                {{ scope.row.hint }}
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="unit" label="单位" width="auto" min-width="10%"></el-table-column>
                          <el-table-column prop="referenceRange" label="参考范围" width="auto"
                            min-width="15%"></el-table-column>
                        </el-table>
                      </el-scrollbar>
                    </el-col>
                    <el-col :span="12">
                      <el-scrollbar style="height: 100%;">
                        <el-table :data="inspectionItems.slice(Math.ceil(inspectionItems.length / 2))" size="small"
                          style="width: 100%;">
                          <el-table-column prop="index" label="序号" width="auto" min-width="10%"></el-table-column>
                          <el-table-column prop="parameter" label="参数" width="auto" min-width="20%"></el-table-column>
                          <el-table-column prop="itemName" show-overflow-tooltip label="项目名称" width="auto"
                            min-width="28%"></el-table-column>
                          <el-table-column prop="result" label="结果" width="auto" min-width="15%"></el-table-column>
                          <el-table-column prop="hint" label="提示" width="auto" min-width="10%">
                            <template #default="scope">
                              <span :class="{ 'high': scope.row.hint === '↑', 'low': scope.row.hint === '↓' }">
                                {{ scope.row.hint }}
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="unit" label="单位" width="auto" min-width="12%"></el-table-column>
                          <el-table-column prop="referenceRange" label="参考范围" width="auto"
                            min-width="15%"></el-table-column>
                        </el-table>
                      </el-scrollbar>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="历史参数">
                  <el-table :data="historicalParams" size="small" style="width: 100%;">
                    <el-table-column label="序号" width="auto" min-width="10%">
                      <template #default="scope">
                        {{ scope.$index + 1 }}
                      </template>
                    </el-table-column>

                    <el-table-column prop="parameter" label="参数" width="auto" min-width="20%"></el-table-column>

                    <el-table-column prop="itemName" show-overflow-tooltip label="项目名称" width="auto"
                      min-width="30%"></el-table-column>

                    <el-table-column prop="unit" label="单位" width="auto" min-width="10%"></el-table-column>

                    <el-table-column prop="referenceRange" label="参考范围" width="auto" min-width="15%">
                      <template #default="scope">
                        <span v-if="scope.row.referenceRange">{{ scope.row.referenceRange }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column v-for="(date, index) in uniqueDates" :key="index" :label="date" width="auto"
                      min-width="15%">
                      <template #default="scope">
                        <span v-html="scope.row.values[index]"></span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="结果解读"></el-tab-pane>
              </el-tabs>
              <!-- 选择显示的条数 -->
              <el-select v-model="neartimes" placeholder="选择显示次数" size="small" @change="handleTimesChange"
                style="position: absolute; right: 10px; top: 8px; width: 140px; height: 32px;">
                <el-option label="近 3 次" select :value="3"></el-option>
                <el-option label="近 5 次" :value="5"></el-option>
                <el-option label="近 10 次" :value="10"></el-option>
                <el-option label="近 20 次" :value="20"></el-option>
                <el-option label="近 30 次" :value="30"></el-option>
                <el-option label="全部" :value="999"></el-option>
              </el-select>
            </div>
            <div class="bottom-info">
              <div class="left-info">
                <span><img src="@/assets/feiji.png" width="12px" style="margin-right: 2px;" />报告状态: <span
                    style="color: green;">{{
                      mapStatus(selectedSample.applyStatus) }}</span></span>
                <span><img src="@/assets/dayin.png" width="12px" style="margin-right: 2px;" />打印状态: <span
                    style="color: orange;">{{ printCount }}</span></span>
                <span
                  v-if="selectedSample.applyStatus !== 'await' && selectedSample.applyStatus !== 'saved' && selectedSample.applyStatus !== null">
                  <img src="@/assets/yinzhang.png" width="12px" style="margin-right: 2px;" />审核人: {{ auditorName }}
                </span>
                <span
                  v-if="selectedSample.applyStatus !== 'await' && selectedSample.applyStatus !== 'saved' && selectedSample.applyStatus !== null">
                  <img src="@/assets/shijian.png" width="12px" style="margin-right: 2px;" />审核时间: {{ auditedDate }}
                </span>
              </div>
              <div class="right-buttons">
                <template
                  v-if="selectedSample.applyStatus === '' || selectedSample.applyStatus === 'await' || selectedSample.applyStatus === null">
                  <el-button type="primary" @click="handleSave" class="ctrl-button small-font">保存</el-button>
                </template>
                <template v-else-if="selectedSample.applyStatus === 'saved'">
                  <el-button type="danger" @click="handleInvalidate" class="ctrl-button small-font">作废</el-button>
                  <el-button type="success" @click="handleAudit" class="ctrl-button small-font">审核</el-button>
                </template>
                <template v-else-if="selectedSample.applyStatus === 'invalid'">
                  <el-button type="success" @click="handleRestore" class="ctrl-button small-font">恢复</el-button>
                </template>
                <template v-else-if="selectedSample.applyStatus === 'audited'">
                  <el-button type="danger" @click="handleCancelAudit" class="ctrl-button small-font">取消审核</el-button>
                  <el-button type="success" @click="handlePublish" class="ctrl-button small-font">发布</el-button>
                </template>
                <template v-else>
                  <el-button type="primary" @click="handleRecycle" class="ctrl-button small-font">回收</el-button>
                  <el-button type="success" @click="handlePrint" class="ctrl-button small-font">打印</el-button>
                </template>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
  <!-- 回收弹出框 -->
  <el-dialog v-model="dialogVisible" title="回收报告原因" width="360px" @close="handleDialogClose" class="recycle-dialog">
    <div class="recycle-reason-container">
      <label for="recycleReason">填写原因:</label>
      <el-input v-model="recycleReason" placeholder="请输入" class="recycle-input"></el-input>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" class="ctrl-button small-font">关闭</el-button>
      <el-button type="primary" @click="confirmRecycle" class="ctrl-button small-font">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { useAuthStore } from '@/stores/authStore';
import { ElLoading, ElMessage } from 'element-plus';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
export default {
  data() {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    return {
      currentRow: null,
      doctorList: [], // 存储医生列表
      neartimes: 3,
      historicalParams: [],
      recycleReason: '',
      inspectionItems: [],
      hospital: '',
      department: '',
      device: '',
      dateStart: thirtyDaysAgo,
      dateEnd: today,
      status: '',
      currentPage: 1,
      pageSize: 23,
      totalRecords: 0,
      selectedRecords: [],
      selectedRows: [],
      samples: [],
      selectedSample: {},
      hospitalList: [],
      departmentList: [],
      deviceList: [],
      filteredDevices: [],
      patientInfo: {
        name: '',
        sex: '',
        bedNum: '',
        birthdayDate: '',
        patientId: '',
        inpatNum: '',
        cardNum: '',
        diagnosis: '',
        sourceCode: '',
        remark: '',
        address: '',
        phone: '',
        sampleType: '',
        sampleDesc: '',
        sampleCollectionDate: '',
        sampleReceiveDate: '',
        analyzeDate: '',
        applyDoctorName: '',
        analyzeDoctorName: '',
        accessionNum: '',
      },
      patientDetails: {},
      auditorName: '',
      auditedDate: '',
      printCount: '',
      dialogVisible: false,
      uniqueDates: [],
    };
  },
  methods: {
    // 回收按钮点击事件
    handleRecycle() {
      this.dialogVisible = true; // 显示回收弹出框
    },

    // 确认回收
    async confirmRecycle() {
      // 在这里添加回收逻辑
      if (this.recycleReason == null || this.recycleReason == '') {
        ElMessage.warning('回收理由不能为空');
        return;
      }
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。');
        return;
      }
      // 构建回收请求的 payload
      const recyclePayload = {
        id: "",
        sampleid: this.selectedSample.id,
        opercode: "", // 操作人代码
        opername: "", // 操作人姓名
        reason: this.recycleReason,
        createtime: ""
      };

      try {
        const authStore = useAuthStore();
        const recycleResponse = await authStore.sampleRecycle(recyclePayload);
        if (recycleResponse.code === 200) {
          // 回收成功后，立即调用状态更新方法
          await this.handleRecycleReport(); // 直接调用回收报告方法
          //this.$message.success('样本已回收');
          this.dialogVisible = false;  // 成功后关闭模态框
          this.recycleReason = '';
        } else {
          this.$message.error(`回收失败: ${recycleResponse.msg}`);
        }
      } catch (error) {
        console.error('回收过程中发生错误:', error);
        this.$message.error('操作失败，请检查网络或联系管理员');
      }
    },

    // 关闭弹出框
    handleDialogClose() {
      this.dialogVisible = false;
    },
    async fetchHospitals() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.hospitallist();
        if (response.code === 200) {
          this.hospitalList = response.data;
          if (this.hospitalList.length > 0) {
            this.hospital = this.hospitalList[0].hospitalCode;
            await this.fetchDepartments(this.hospital);
          }
        }
      } catch (error) {
        console.error('Error fetching hospitals:', error);
      }
    },
    async fetchDepartments(hospitalCode) {
      try {
        const authStore = useAuthStore();
        const response = await authStore.getDepartmentList(hospitalCode);
        if (response.code === 200) {
          this.departmentList = response.data;
          if (this.departmentList.length > 0) {
            this.department = this.departmentList[0].id;
            this.filterDevicesByDepartment();
          }
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    },
    async fetchDevices() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.devicelist();
        if (response.code === 200) {
          this.deviceList = response.data;
          this.filterDevicesByDepartment();
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    },
    filterDevicesByDepartment() {
      if (this.department) {
        this.filteredDevices = this.deviceList.filter(device => device.deptId === this.department);
        if (this.filteredDevices.length > 0) {
          this.device = this.filteredDevices[0].id;
        }
      } else {
        this.filteredDevices = [];
        this.device = '';
      }
    },
    async search() {
      const loading = ElLoading.service({ fullscreen: true });
      try {
        const authStore = useAuthStore();
        let params = {
          deviceId: this.device,
          startCheckTime: Math.floor(new Date(this.dateStart).getTime() / 1000),
          endCheckTime: Math.floor(new Date(this.dateEnd.setHours(23, 59, 59, 999)).getTime() / 1000),
          //startCheckTime: 1693497600,
          //endCheckTime: 1696089599,
          pageIndex: this.currentPage,
          pageSize: this.pageSize
        };
        // 只有当状态被明确选择时才添加到参数中
        if (this.status) {
          params.status = this.status;
        }

        const response = await authStore.getSamples(params);
        if (response.code === 200) {
          if (response.data.total === 0) {
            ElMessage({
              message: '没有找到匹配的记录，请调整搜索条件。',
              type: 'info'
            });
          } else {
            //检测样本
            this.samples = response.data.data.map((item) => ({
              id: item.id || '',
              sampleNum: item.sampleNum || '',
              accessionNum: item.accessionNum || '',
              deviceId: item.deviceId || '',
              deviceName: item.deviceName || '',
              requestDeptName: item.requestDeptName || '',
              patientId: item.patientId || '',
              creatorEmplId: item.creatorEmplId || '',
              creatorEmplName: item.creatorEmplName || '',
              payStatus: item.payStatus || '',
              auditedEmplId: item.auditedEmplId || '',
              auditedEmplName: item.auditedEmplName || '',
              operationEmplId: item.operationEmplId || '',
              operationEmplName: item.operationEmplName || '',
              printCount: item.printCount || '',
              applyStatus: item.applyStatus,
              analyzeDate: item.analyzeDate || '',
              auditedDate: item.auditedDate || '',
              updateDate: item.updateDate || '',
              createDate: item.createDate || '',
              deptId: item.deptId || '',
              listParams: item.listParams ? item.listParams.map(param => ({
                id: param.id || '',
                code: param.code || '',
                name: param.name || '',
                unit: param.unit || '',
                sort: param.sort || 0,
                status: param.status || 0,
                type: param.type || '',
                calcType: param.calcType || '',
                payType: param.payType || '',
                refRangeMin: param.refRangeMin || '',
                refRangeMax: param.refRangeMax || '',
                criticalRangeMin: param.criticalRangeMin || '',
                criticalRangeMax: param.criticalRangeMax || '',
                refRange: param.refRange || '',
                criticalRange: param.criticalRange || '',
                value: param.value || '',
                sampleId: param.sampleId || '',
                createTime: param.createTime ? new Date(param.createTime).toLocaleString() : '',
                deviceId: param.deviceId || '',
                dotNum: param.dotNum || 0,
                listSpecialParam: param.listSpecialParam || ''
              })) : [],
              patient: item.patient || '',
              isSaved: item.isSaved || '',
            }));
            this.totalRecords = response.data.total;
            this.selectFirstRow();
          }
        } else {
          ElMessage.error(response.msg || '获取数据失败');
        }
      } catch (error) {
        console.error('Error fetching samples:', error);
        ElMessage.error('搜索请求失败');
      } finally {
        loading.close();
      }
    },

    // 辅助方法：将状态码转换为中文
    mapStatus(status) {
      const statusMap = {
        'await': '待绑定',
        'saved': '已保存',
        'audited': '已审核',
        'publish': '已发布',
        'invalid': '已作废'
      };
      // 考虑到 null 或未定义状态，均视为 '待绑定'
      return status ? (statusMap[status] || '未知状态') : '待绑定';
    },
    selectFirstRow() {
      if (this.samples.length > 0) {
        this.$nextTick(() => {
          this.$refs.leftTable.setCurrentRow(this.samples[0]); // 默认选中第一行
          this.handleRowClick(this.samples[0]);
        });
      }
    },
    reset() {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);
      this.hospital = '';
      this.department = '';
      this.device = '';
      this.dateStart = thirtyDaysAgo;
      this.dateEnd = today;
      this.status = '';
      this.currentPage = 1;
      this.pageSize = 23;
      this.samples = [];
      this.patientInfo = {}; // 清空患者信息
    },
    handleTimesChange(value) {
      // 更新选中的显示次数
      this.neartimes = value;
      console.log(1111)
      // 重新加载选中的行数据
      if (this.selectedPatientId) {
        this.fetchHistoricalParams(this.selectedPatientId);
      }
    },
    async fetchHistoricalParams(patientId) {
      const authStore = useAuthStore();
      try {
        const response = await authStore.getRecentInfo(patientId, this.neartimes); // 获取最近的3次数据
        if (response.code === 200) {
          // 提取所有唯一的参数
          const paramsMap = new Map();

          response.data.forEach(item => {
            item.listParams.forEach(param => {
              if (!paramsMap.has(param.code)) {
                paramsMap.set(param.code, {
                  parameter: param.code,
                  itemName: param.name,
                  unit: param.unit,
                  referenceRange: this.formatReferenceRange(param.refRangeMin, param.refRangeMax),
                  values: []
                });
              }
            });
          });

          // 为每个日期填充参数的值
          response.data.forEach(item => {
            item.listParams.forEach(param => {
              const paramData = paramsMap.get(param.code);
              const value = parseFloat(param.value);
              let hint = '';
              if (!isNaN(value)) {
                // 根据参考范围判断提示箭头
                const refRangeMin = parseFloat(param.refRangeMin);
                const refRangeMax = parseFloat(param.refRangeMax);
                if (!isNaN(refRangeMin) && value < refRangeMin) {
                  hint = '↓';
                } else if (!isNaN(refRangeMax) && value > refRangeMax) {
                  hint = '↑';
                }
              }
              const formattedValue = `${value}${hint ? `<span style="color:${hint === '↑' ? 'red' : 'green'};">${hint}</span>` : ''}`;
              paramData.values.push(formattedValue);
            });
          });

          this.historicalParams = Array.from(paramsMap.values());

          // 提取唯一的日期
          this.uniqueDates = response.data
            .map(item => this.formatDateH(item.analyzeDate))
            .filter((date, index, self) => self.indexOf(date) === index);
        } else {
          ElMessage.error(response.msg || '获取历史参数数据失败');
        }
      } catch (error) {
        console.error('Error fetching historical parameters:', error);
      }
    },

    formatReferenceRange(refRangeMin, refRangeMax) {
      if (!refRangeMin && !refRangeMax) return '';
      if (!refRangeMin) return `≤${refRangeMax}`;
      if (!refRangeMax) return `≥${refRangeMin}`;
      return `${refRangeMin}-${refRangeMax}`;
    },
    async handleRowClick(row) {
      this.currentRow = row;
      const originalStatus = this.status;  // 保存原始状态
      this.auditedDate = '';
      this.auditorName = '';
      this.status = '';
      this.printCount = '';
      this.patientDetails = {};
      this.selectedSample = this.samples.find(patient => patient.sampleNum === row.sampleNum);
      if (this.selectedSample) {
        this.patientInfo = { ...this.selectedSample }; // 使用返回的JSON中的sample数据
        const authStore = useAuthStore();
        // 无论状态如何，都尝试获取病人详细信息
        if (this.patientInfo.patient == '') {
          try {
            const response = await authStore.getPatient({ accessionNum: this.selectedSample.accessionNum });
            if (response.data && response.data.length > 0) {
              const patient = response.data[0];
              patient.age = this.calculateAge(patient.birthdayDate);
              this.patientDetails = patient;
            } else {
              ElMessage.warning('本次搜索没有查询到患者信息');
            }
          } catch (error) {
            console.error('Error fetching patient details:', error);
          }
        } else {
          this.patientDetails = this.patientInfo.patient;
        }


        // 尝试获取检查项目数据
        const id = this.selectedSample.id;
        const res = await authStore.getSample(id);
        if (res.data && res.data.length > 0) {
          this.selectedSample.listParams = res.data;
        }
        //this.status = selectedPatient.status;
        this.printCount = this.selectedSample.printCount === '' ? "未打印" : "已打印";

        // 获取审核人信息
        if (this.selectedSample.auditedEmplId) {
          const userResponse = await authStore.getUser({ emplIds: this.selectedSample.auditedEmplId, pageIndex: 1, pageSize: 1 });
          if (userResponse.data && userResponse.data.data.length > 0) {
            this.auditorName = userResponse.data.data[0].name;
            this.auditedDate = this.formatDateH(this.selectedSample.auditedDate);
          }
        }

        // 获取历史参数数据
        this.selectedPatientId = row.patient.patientId;
        await this.fetchHistoricalParams(this.selectedPatientId);

        // 处理检查项目数据
        if (this.selectedSample.listParams) {
          this.inspectionItems = this.selectedSample.listParams.map((item, index) => {
            const value = parseFloat(item.value);
            let hint = '';
            let formattedRefRange = item.refRange;

            if (item.refRange) {
              const refRange = item.refRange.match(/(≥?)([0-9.]+)(&&≤?([0-9.]+))?/);
              if (refRange) {
                const lowerBound = refRange[1] === '≥' ? parseFloat(refRange[2]) : -Infinity;
                const upperBound = refRange[4] ? parseFloat(refRange[4]) : Infinity;

                if (value < lowerBound) {
                  hint = '↓';
                } else if (value > upperBound) {
                  hint = '↑';
                }

                formattedRefRange = `${refRange[2]}-${refRange[4] || ''}`;
              }
            }

            return {
              index: index + 1,
              parameter: item.code,
              itemName: item.name,
              result: item.value,
              hint: hint,
              unit: item.unit,
              referenceRange: formattedRefRange
            };
          });
        } else {
          this.inspectionItems = [];
        }
      }
      this.status = originalStatus;  // 恢复之前的状态
    },
    async fetchAllDoctors() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.getUser({ pageIndex: 1, pageSize: 50 }); // 不传任何参数，获取所有用户
        if (response.code === 200 && response.data && response.data.data.length > 0) {
          console.log(response);
          this.doctorList = response.data.data; // 将获取的医生列表存储在 doctorList 中
        } else {
          ElMessage.error(response.msg || '获取医生列表失败');
        }
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    },
    // 动态搜索医生的方法 (在用户输入时调用) - 使用防抖机制
    fetchDoctors: debounce(async function (searchKey) {
      //this.loadingDoctors = true;
      try {
        const authStore = useAuthStore();
        // 根据用户输入的 searchKey 参数进行搜索
        const response = await authStore.getUser({
          searchKey: searchKey,
          pageIndex: 1,
          pageSize: 50
        });
        if (response.code === 200 && response.data && response.data.data.length > 0) {
          this.doctorList = response.data.data; // 更新医生列表
        } else {
          this.doctorList = [];  // 如果没有结果，清空医生列表
        }
      } catch (error) {
        console.error('Error fetching doctors:', error);
        this.doctorList = [];
      } finally {
        //this.loadingDoctors = false;
      }
    }, 500),
    calculateAge(birthday) {
      if (!birthday) return '';
      const birthDate = dayjs(birthday);
      const today = dayjs();
      return today.diff(birthDate, 'year'); // 计算年龄
    },
    formatDate(date) {
      if (!date) return '';
      return dayjs(date).format('YYYY-MM-DD'); // 格式化为 年-月-日
    },
    formatDateH(date) {
      if (!date) return '';
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss'); // 格式化为 年-月-日-时分秒
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.search();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.search();
    },
    getItemsForColumn(colIndex) {
      return this.items[colIndex];
    },
    getItemClass(colIndex, rowIndex) {
      if (colIndex === 3 && rowIndex === 0) {
        return 'double-height';
      }
      if (colIndex === 4 && rowIndex === 1) {
        return 'double-height';
      }
      if (colIndex === 6) {
        return 'center-content';
      }
      return '';
    },
    getValue(key) {
      return this.patientDetails[key];
    },
    async fetchPatient() {
      if (this.patientDetails.accessionNum) {
        const authStore = useAuthStore();
        try {
          const response = await authStore.getPatient({ accessionNum: this.patientDetails.accessionNum });
          if (response.data && response.data.length > 0) {
            this.patientDetails = response.data[0];
            // 可以在这里添加更多的逻辑，比如更新界面显示
          } else {
            ElMessage.warning('本次搜索没有查询到患者信息');
          }
        } catch (error) {
          console.error('Error fetching patient details:', error);
          ElMessage.error('获取患者信息失败');
        }
      } else {
        ElMessage.warning('请输入条码号');
      }
    },
    convertToISOTimestamp(dateString) {
      // 检查是否已经是ISO 8601格式 (简单检查)
      const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
      if (iso8601Regex.test(dateString)) {
        return dateString; // 如果已经是ISO格式，则原样返回
      }

      // 假设输入日期时间为本地日期格式，进行转换为ISO 8601格式
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        return date.toISOString();
      } else {
        // 如果转换失败（例如，由于无效的日期字符串），返回原始字符串或进行错误处理
        return dateString;
      }
    },
    async handleSave() {
      // 字段验证
      if (!this.patientDetails.name || !this.patientDetails.patientId ||
        !this.patientDetails.sampleCollectionDate || !this.patientDetails.sampleReceiveDate ||
        !this.selectedSample.analyzeDate || !this.patientDetails.analyzeDoctorName) {
        ElMessage.warning('请完整填写必需信息，并确保所有日期和时间字段都已填写。');
        return;
      }
      // 时间验证
      const collectionDate = new Date(this.patientDetails.sampleCollectionDate).getTime();
      const receiveDate = new Date(this.patientDetails.sampleReceiveDate).getTime();
      const analyzeDate = new Date(this.selectedSample.analyzeDate).getTime();
      if (!(analyzeDate > receiveDate && receiveDate > collectionDate)) {
        ElMessage.warning('检测时间必须晚于接收时间，接收时间必须晚于采样时间。');
        return;
      }
      // 获取当前选中的样本
      if (!this.selectedSample) {
        ElMessage.warning('没有找到选中的样本，请确认是否选中了样本。');
        return;
      }
      // 构建提交到后端的对象
      const patientData = {
        id: this.patientDetails.id,
        patientId: this.patientDetails.patientId,
        sourceCode: this.patientDetails.sourceCode,
        inpatNum: this.patientDetails.inpatNum,
        accessionNum: this.patientDetails.accessionNum,
        cardNum: this.patientDetails.cardNum,
        name: this.patientDetails.name,
        sex: this.patientDetails.sex,
        bedNum: this.patientDetails.bedNum,
        deptId: this.patientDetails.deptId,
        deptName: this.patientDetails.deptName === null ? '' : this.patientDetails.deptName,
        applyNo: this.patientDetails.applyNo === null ? 'applyNo' : this.patientDetails.applyNo,
        orderId: this.patientDetails.orderId === null ? 'orderid' : this.patientDetails.orderId,
        orderCode: this.patientDetails.orderCode === null ? 'orderCode' : this.patientDetails.orderCode,
        orderName: this.patientDetails.orderName === null ? 'orderName' : this.patientDetails.orderName,
        diagnosis: this.patientDetails.diagnosis,
        remark: this.patientDetails.remark,
        applyDoctorCode: this.patientDetails.applyDoctorCode,
        applyDoctorName: this.patientDetails.applyDoctorName,
        address: this.patientDetails.address,
        phone: this.patientDetails.phone,
        sampleType: this.patientDetails.sampleType,
        sampleMode: this.patientDetails.sampleMode,
        sampleDesc: this.patientDetails.sampleDesc,
        analyzeDoctorCode: this.patientDetails.analyzeDoctorCode,
        analyzeDoctorName: this.patientDetails.analyzeDoctorName,
        birthdayDate: this.convertToISOTimestamp(this.patientDetails.birthdayDate),
        sampleCollectionDate: this.convertToISOTimestamp(this.patientDetails.sampleCollectionDate),
        sampleReceiveDate: this.convertToISOTimestamp(this.patientDetails.sampleReceiveDate)
      };
      console.log(patientData);

      const sampleData = {
        id: this.selectedSample.id,
        sampleNum: this.selectedSample.sampleNum,
        accessionNum: this.selectedSample.accessionNum,
        deviceId: this.selectedSample.deviceId,
        patientPk: this.selectedSample.patientPk,
        patientId: this.selectedSample.patientId,
        creatorEmplId: this.selectedSample.creatorEmplId,
        payStatus: this.selectedSample.payStatus,
        operationEmplId: this.selectedSample.operationEmplId,
        applyStatus: 'saved',
        analyzeDate: this.convertToISOTimestamp(this.selectedSample.analyzeDate),
        updateDate: new Date().toISOString()  // 直接获取当前日期和时间的ISO格式
        //listParams: this.selectedSample.listParams
      };

      const saveData = {
        patient: patientData,
        sample: sampleData
      };

      // 调用store中的保存方法
      try {
        const authStore = useAuthStore();
        const response = await authStore.sampleSave(saveData); // 确保传递JSON字符串
        if (response.code === 200) {
          ElMessage.success('样本保存成功');
          // 更新samples数组中的selectedSample
          const index = this.samples.findIndex(sample => sample.id === this.selectedSample.id);
          if (index !== -1) {
            this.samples[index] = { ...this.samples[index], ...response.data }; // 合并更新数据
            this.selectedSample = this.samples[index]; // 更新selectedSample引用
          }
          // 重置选中的行
          this.$nextTick(() => {
            this.$refs.leftTable.setCurrentRow(this.selectedSample);
          });
        } else {
          ElMessage.error(`保存失败: ${response.msg}`);
        }
      } catch (error) {
        console.error('保存过程中发生错误:', error);
        ElMessage.error('保存失败，请检查网络或联系管理员');
      }
    },
    formatDateTime(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString(); // 根据需要可能要调整区域设置
    },
    handleCurrentChange(currentRow) {
      this.currentRow = currentRow;
    },
    tableRowClassName({ row }) {
      if (row === this.currentRow) {
        return 'row-highlight';
      }
      return '';
    },
    // 处理申请医生选择变化
    handleApplyDoctorChange(selectedName) {
      const selectedDoctor = this.doctorList.find(doctor => doctor.name === selectedName);
      if (selectedDoctor) {
        this.patientDetails.applyDoctorName = selectedDoctor.name;
        this.patientDetails.applyDoctorCode = selectedDoctor.code;
      }
    },
    // 处理检测医生选择变化
    handleAnalyzeDoctorChange(selectedName) {
      const selectedDoctor = this.doctorList.find(doctor => doctor.name === selectedName);
      if (selectedDoctor) {
        this.patientDetails.analyzeDoctorName = selectedDoctor.name;
        this.patientDetails.analyzeDoctorCode = selectedDoctor.code;
      }
    },
    async handleInvalidate() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。');
        return;
      }

      // 构建请求体
      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            applyStatus: "saved" // 确保这是在发起作废请求前的必要状态
          }
        ],
        targetStatus: "invalid"
      };

      try {
        const authStore = useAuthStore();
        const response = await authStore.sampleUpdate(payload);
        if (response.code === 200) {
          ElMessage.success('样本已成功作废');
          // 更新前端状态
          this.selectedSample.applyStatus = "invalid";
          const index = this.samples.findIndex(sample => sample.id === this.selectedSample.id);
          if (index !== -1) {
            this.samples[index].applyStatus = "invalid";
            this.currentRow = this.samples[index];
          }
          // 你可能还需要重新加载或更新UI的其他部分，视具体情况而定
        } else {
          ElMessage.error(`作废失败: ${response.msg}`);
        }
      } catch (error) {
        console.error('作废过程中发生错误:', error);
        ElMessage.error('作废失败，请检查网络或联系管理员');
      }
    },
    async handleRestore() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。');
        return;
      }

      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            applyStatus: "invalid"
          }
        ],
        targetStatus: "await"
      };

      try {
        const authStore = useAuthStore();
        const response = await authStore.sampleUpdate(payload);
        if (response && response.code === 200) {
          ElMessage.success('样本已恢复到待绑定状态');
          this.selectedSample.applyStatus = "await";  // 更新内存中的状态
          // 更新列表中对应的样本状态
          const index = this.samples.findIndex(sample => sample.id === this.selectedSample.id);
          if (index !== -1) {
            this.samples[index].applyStatus = "await";
            this.currentRow = this.samples[index];
          }
        } else {
          ElMessage.error(`恢复失败: ${response.msg || '未知错误'}`);
        }
      } catch (error) {
        console.error('恢复过程中发生错误:', error);
        ElMessage.error('恢复失败，请检查网络或联系管理员');
      }
    },

    async handleAudit() {//审核
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。');
        return;
      }

      // 构建请求体
      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            applyStatus: "saved" // 确保这是在发起作废请求前的必要状态
          }
        ],
        targetStatus: "audited"
      };

      try {
        const authStore = useAuthStore();
        const response = await authStore.sampleUpdate(payload);
        if (response.code === 200) {
          ElMessage.success('审核成功');
          // 更新前端状态
          this.selectedSample.applyStatus = "audited";
          const index = this.samples.findIndex(sample => sample.id === this.selectedSample.id);
          if (index !== -1) {
            this.samples[index].applyStatus = "audited";
            this.currentRow = this.samples[index];
          }
          // 你可能还需要重新加载或更新UI的其他部分，视具体情况而定
        } else {
          ElMessage.error(`审核失败: ${response.msg}`);
        }
      } catch (error) {
        console.error('审核过程中发生错误:', error);
        ElMessage.error('审核失败，请检查网络或联系管理员');
      }
    },
    async handleRestore() {//恢复样本
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。');
        return;
      }

      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            applyStatus: "invalid"
          }
        ],
        targetStatus: "await"
      };

      try {
        const authStore = useAuthStore();
        const response = await authStore.sampleUpdate(payload);
        if (response && response.code === 200) {
          ElMessage.success('样本已恢复到待绑定状态');
          this.selectedSample.applyStatus = "await";  // 更新内存中的状态
          // 更新列表中对应的样本状态
          const index = this.samples.findIndex(sample => sample.id === this.selectedSample.id);
          if (index !== -1) {
            this.samples[index].applyStatus = "await";
            this.currentRow = this.samples[index];
          }
        } else {
          ElMessage.error(`恢复失败: ${response.msg || '未知错误'}`);
        }
      } catch (error) {
        console.error('恢复过程中发生错误:', error);
        ElMessage.error('恢复失败，请检查网络或联系管理员');
      }
    },
    async handleCancelAudit() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。');
        return;
      }
      // 构建请求体
      const payload = {
        samples: [{
          id: this.selectedSample.id,
          applyStatus: "audited" // 确保这是在发起取消审核请求前的必要状态
        }],
        targetStatus: "saved"
      };

      try {
        const authStore = useAuthStore();
        const response = await authStore.sampleUpdate(payload); // 确保这个API地址和操作是准确的
        if (response.code === 200) {
          ElMessage.success('审核已取消');
          this.selectedSample.applyStatus = 'saved'; // 更新本地状态为已保存
          // 更新列表中的样本状态
          const index = this.samples.findIndex(sample => sample.id === this.selectedSample.id);
          if (index !== -1) {
            this.samples[index].applyStatus = 'saved';
            this.currentRow = this.samples[index];
          }
        } else {
          ElMessage.error(`取消审核失败: ${response.msg}`);
        }
      } catch (error) {
        console.error('取消审核过程中发生错误:', error);
        ElMessage.error('操作失败，请检查网络或联系管理员');
      }
    },

    async handlePublish() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。');
        return;
      }
      // 构建请求体
      const payload = {
        samples: [{
          id: this.selectedSample.id,
          applyStatus: "audited" // 确保这是在发起取消审核请求前的必要状态
        }],
        targetStatus: "publish"
      };

      try {
        const authStore = useAuthStore();
        const response = await authStore.sampleUpdate(payload); // 确保这个API地址和操作是准确的
        if (response.code === 200) {
          ElMessage.success('发布成功');
          this.selectedSample.applyStatus = 'publish'; // 更新本地状态为已保存
          // 更新列表中的样本状态
          const index = this.samples.findIndex(sample => sample.id === this.selectedSample.id);
          if (index !== -1) {
            this.samples[index].applyStatus = 'publish';
            this.currentRow = this.samples[index];
          }
        } else {
          ElMessage.error(`发布失败: ${response.msg}`);
        }
      } catch (error) {
        console.error('发布过程中发生错误:', error);
        ElMessage.error('发布失败，请检查网络或联系管理员');
      }
    },
    async handleRecycleReport() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。');
        return;
      }
      // 构建请求体
      const payload = {
        samples: [{
          id: this.selectedSample.id,
          // 你可能需要提供当前状态，根据业务逻辑决定是否需要
          applyStatus: "publish" // 当前状态
        }],
        targetStatus: "saved" // 目标状态为已保存
      };

      try {
        const authStore = useAuthStore();
        const response = await authStore.sampleUpdate(payload); // 使用 Vuex 或 Pinia 的 authStore 方法
        if (response.code === 200) {
          ElMessage.success('报告已回收');
          this.selectedSample.applyStatus = 'saved'; // 更新本地状态为已保存
          // 更新列表中的样本状态
          const index = this.samples.findIndex(sample => sample.id === this.selectedSample.id);
          if (index !== -1) {
            this.samples[index].applyStatus = 'saved';
            this.currentRow = this.samples[index]; // 更新当前行为选中行
          }
        } else {
          ElMessage.error(`回收失败: ${response.msg}`);
        }
      } catch (error) {
        console.error('回收报告过程中发生错误:', error);
        ElMessage.error('回收失败，请检查网络或联系管理员');
      }
    },
    async handlePrint() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。');
        return;
      }

      try {
        const authStore = useAuthStore(); // 调用store中的方法
        const response = await authStore.samplePrint(this.selectedSample.id); // 发送请求获取PDF
        if (response && response.data) {
          // 创建一个Blob对象存储PDF文件
          const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

          // 创建一个隐藏的链接元素并触发下载
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', this.selectedSample.id + 'sample_report.pdf'); // 设置默认下载文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // 打开新窗口进行打印
          const printWindow = window.open(fileURL);
          if (printWindow) {
            printWindow.onload = function () {
              printWindow.focus();
              printWindow.print(); // 调用浏览器的打印功能
            };
          } else {
            ElMessage.error('无法打开打印窗口，请检查弹出窗口是否被阻止。');
          }
        } else {
          ElMessage.error('未获取到PDF文件，请检查网络或联系管理员。');
        }
      } catch (error) {
        console.error('打印过程中发生错误:', error);
        ElMessage.error('打印失败，请检查网络或联系管理员。');
      }
    },
  },

  async mounted() {
    await this.fetchHospitals();
    await this.fetchDevices();
    await this.fetchAllDoctors(); // 只加载一次医生列表
    //this.search(); // 初始加载时搜索数据
  },
};
</script>

<style scoped>
.registration-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
  width: calc(100% - 20px);
  background-color: #474747;
  overflow: hidden;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 12px;
}

.header {
  background-color: #474747;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.filter-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-bar label {
  font-size: 16px;
}

.filter-input,
.filter-date {
  width: 150px;
}

.ctrl-button {
  width: 88px;
}

.content {
  border-radius: 12px;
  flex: 1;
  padding: 10px 10px 5px 10px;
  background-color: #f5f6f7;
}

.top {
  padding: 5px;
  padding-bottom: 12px;
  color: #057AB2;
  text-align: left;
  font-weight: bold;
  box-sizing: border-box;
  font-size: 16px;
}

.left-pane {
  background-color: white;
  border-radius: 12px;
  padding: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

:deep(.small-table .el-table__cell) {
  padding: 3px;
  font-size: 12px;
}

.right-pane {
  margin-left: 5px;
  border-radius: 12px;
  position: relative;
  font-size: 12px;
}

.table-container {
  height: 100%;
  box-sizing: border-box;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  margin-top: auto;
}

.total-records {
  margin-left: 10px;
  color: #666;
  font-size: 12px;
}

.action-buttons {
  position: absolute;
  right: 15px;
  bottom: 8px;
  display: flex;
  gap: 10px;
}

.barcode-button {
  margin: 0;
}

.form-row {
  margin-bottom: 10px;
}

.form-row label {
  width: 100px;
  display: inline-block;
  text-align: right;
  margin-right: 5px;
  font-size: 12px;
}

.form-header .el-col {
  height: auto;
}

.form-header .el-input {
  width: 200px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 5px;
}

:deep(.el-table__inner-wrapper:before) {
  height: 0;
}

.form-header .el-select {
  width: 160px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 5px;
}

.el-header {
  height: auto;

}

.high {
  color: red;
}

.low {
  color: blue;
}

/* 设置el-tabs的高度和滚动条 */
.el-tabs {
  display: flex;
  flex-direction: column;
}

.el-tabs__content {
  flex: 1;

}

.el-tab-pane {
  height: 100%;
}

.el-row {
  height: 100%;
}

.el-col {
  height: 100%;
}

.el-table {
  height: 100%;
}

.content-area {
  height: auto;
  background-color: white;
  padding-top: 0px;
  border-radius: 8px;
}

:deep(.content-area .el-tabs__content) {
  height: 520px;
}

.grid-container {
  display: flex;
  height: 100px;
  position: relative;
  padding: 5px 5px;
  background-color: #FFFFFF;
  border-radius: 8px;
}

.el-main {
  padding: 10px;
}

.grid-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  /* 每列的左右间距 */
  overflow: hidden;
  /* 防止文字溢出 */
  position: relative;
  /* 相对定位用于竖线 */
}

.grid-item {
  text-align: left;
  /* 文字居左 */
  display: flex;
  align-items: center;
  padding: 4px 6px;
  /* 增加内边距 */
  font-size: 14px;
  /* 字体大小 */
  font-weight: 400;
  height: 30px;
  /* 每行高度 */
  margin: 2px 0;
  /* 行间距 */
  white-space: nowrap;
  /* 禁止换行 */
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超过宽度用...表示 */
}

.grid-item span {
  text-align: left;
}

.grid-item label {
  text-align: left;
  margin-right: 5px;
  width: auto;
}

.grid-item .label2 {
  font-size: 12px;
  color: #057AB2;
}

.grid-item .label3 {
  color: #5E6266;
}

.grid-item .label4 {
  font-weight: 500;
  color: #003750;
  background-color: #DDDDE3;
  padding: 2px;
  border-radius: 4px;
}

.double-height {
  height: 64px;
  /* 设置为正常的两行高度 */
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超过宽度用...表示 */
}

.center-content {
  height: 64px;
  /* 第七列的每一行高度为正常的两行高度 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-line {
  width: 1px;
  height: 50%;
  background-color: #ddd;
  /* 竖线颜色 */
  position: absolute;
  right: 0;
  /* 竖线位置调整 */
  top: 50%;
  /* 垂直居中 */
  transform: translateY(-50%);
  /* 垂直居中 */
}

.value {
  white-space: normal;
  /* 允许换行 */
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超过宽度用...表示 */
}

:deep(.grid-container .el-header) {
  padding: 0;
}

/*底部信息*/
.bottom-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-info .left-info span {
  margin-right: 10px;
}

.right-buttons {
  display: flex;
}

.recycle-reason-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  /* 设置间距 */
}

.recycle-reason-container label {
  margin-right: 10px;
}

.recycle-dialog .el-dialog__header {
  font-weight: 700;
}

.recycle-input {
  flex: 1;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.ctrl-button {
  width: 88px;
}

.el-select .el-input__suffix {
  display: flex;
  align-items: center;
}

.el-input__suffix-inner {
  position: relative;
  right: 0;
}

.el-select__caret {
  display: block !important;
}

.row-highlight {
  background-color: #c0ddeb !important;
  /* 选择你喜欢的颜色 */
}
</style>