<template>
  <div class="home-page">
    <Sidebar @toggleCollapse="handleToggleCollapse" />
    <div class="content">
      <router-view :is-aside-collapsed="isAsideCollapsed"></router-view> <!-- 显示当前路由匹配的组件 -->
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import { useAuthStore } from '@/stores/authStore'; // 认证状态存储
import { useRouter } from 'vue-router'; // 路由器
import { onMounted, ref } from 'vue'; // 引入 onMounted 钩子

export default {
  name: 'Home',
  components: {
    Sidebar
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const isAsideCollapsed = ref(false);

    const logout = () => {
      authStore.clearToken(); // 清除Token
      router.push({ name: 'Login' }); // 跳转到登录页
    };

    const handleToggleCollapse = (isCollapsed) => {
      isAsideCollapsed.value = isCollapsed;
    };

    onMounted(() => {
      // 检查 token 是否存在或是否过期
      if (!authStore.token || authStore.isTokenExpired()) {
        router.push({ name: 'Login' }); // 跳转到登录页
      }
    });

    return {
      logout,
      isAsideCollapsed,
      handleToggleCollapse,
    };
  },
};
</script>

<style scoped>
.home-page {
  display: flex;
  height: 100vh; /* 设置视图高度为100%视窗高度 */
  overflow: hidden;
}

.content {
  flex: 1;
  background-color: #474747; /* 设置内容背景色 */
}
:deep(.el-switch.is-checked .el-switch__core){
  background-color: #057ab2;
  border-color: #057ab2;
}
:deep(.el-pagination.is-background .el-pager li.is-active){
  background-color: #057ab2;
  border-color: #057ab2;
}
:deep(.el-button--primary){
  background-color: #057ab2;
  border-color: #057ab2;
}
:deep(.el-button--primary:hover){
  background-color: #045a8d;
  border-color: #045a8d;
}
:deep(.el-checkbox){
  color: #057ab2;
}
:deep(.el-checkbox__input.is-checked+.el-checkbox__label){
  color: #057ab2;
}
:deep(.el-checkbox__input.is-checked .el-checkbox__inner){
  background-color: #057ab2;
}
:deep(.el-radio__input.is-checked .el-radio__inner){
  background-color: #057ab2;
}
:deep(.el-radio__input.is-checked+.el-radio__label){
  color: #057ab2;
}
:deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner){
  color: #057ab2;
  background-color:#057ab2 ;
}
</style>
